import React, { useMemo } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

/* Components */
import { Button } from "@components/Button";

/* Hooks */
import { useUniqueId } from "@src/hooks";

type MouseEvent = React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;

type Props = {
  currentPage: number;
  totalPages: number;
  onBack: (e: MouseEvent) => void;
  onNext: (e: MouseEvent) => void;
  onButtonClick: (page: number) => void;
  isFetching: boolean;
  prefix: string;
  wrapperClassName?: string;
};

type PaginationProps = {
  page: number;
  isCurrent: boolean;
  onClick: () => void;
};

export const Pagination: React.FC<Props> = (props) => {
  const {
    currentPage,
    prefix,
    isFetching,
    onBack,
    wrapperClassName,
    onNext,
    onButtonClick,
    totalPages,
  } = props;

  /* Memos */
  const paginationNums = useMemo(
    () => new Array(totalPages).fill(0),
    [totalPages],
  );
  const paginationKey = useUniqueId(`${prefix}-pagination-`);

  return (
    <div className={`flex gap-4 ${wrapperClassName}`}>
      <Button
        disabled={currentPage === 1 || isFetching}
        onClick={onBack}
        iconButton
        leftIcon={() => <ChevronLeft />}
      />
      <div className="flex gap-2">
        {paginationNums.map((_, i) => (
          <PaginationButton
            isCurrent={i + 1 === currentPage}
            key={paginationKey + i}
            onClick={() => onButtonClick(i)}
            page={i + 1}
          />
        ))}
      </div>
      <Button
        disabled={totalPages === currentPage || isFetching}
        onClick={onNext}
        iconButton
        leftIcon={() => <ChevronRight />}
      />
    </div>
  );
};

const PaginationButton: React.FC<PaginationProps> = (props) => {
  const { onClick, page, isCurrent } = props;
  return (
    <Button onClick={onClick} variant={isCurrent ? "solid" : "outline"}>
      {page}
    </Button>
  );
};
